"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { cn, getYoutubeLink } from "@/lib/utils";
import useMobile from "@/lib/hooks/useMobile";
import YoutubeVideo from "../Youtube/video";
import { nanoid } from "nanoid";
import { INDEX_PRODUCT_VIDEO } from "@/lib/queries/product-new-featured-bestSelling";
import client from "@/lib/ApolloClient/apolloClient";
import { Skeleton } from "../ui/skeleton";

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

/**
 * 从产品元数据中获取视频URL
 * @param product 产品数据
 * @returns 视频URL和类型
 */
function getVideoFromMetaData(product: any) {
  if (!product?.metaData) return { url: '', type: 'mp4' };

  // 首先尝试查找woodmart_wc_video_gallery特定元数据
  const woodmartVideoMeta = product.metaData.find((meta: { key: string; value: any }) =>
    meta.key === "woodmart_wc_video_gallery" && meta.value
  );

  if (woodmartVideoMeta && typeof woodmartVideoMeta.value === 'string') {
    try {
      // 解析JSON字符串
      const videoData = JSON.parse(woodmartVideoMeta.value);

      // 遍历所有视频数据，查找第一个可用的视频URL
      for (const key in videoData) {
        const video = videoData[key];

        // 优先使用上传的MP4视频
        if (video.upload_video_url) {
          return { url: video.upload_video_url, type: 'mp4' };
        }

        // 其次使用YouTube视频
        if (video.youtube_url) {
          return { url: video.youtube_url, type: 'youtube' };
        }

        // 最后使用Vimeo视频
        if (video.vimeo_url) {
          return { url: video.vimeo_url, type: 'vimeo' };
        }
      }
    } catch (error) {
      console.error('Error parsing video metadata:', error);
    }
  }

  // 如果没有找到特定的视频元数据，尝试查找其他包含'video'的元数据字段
  const videoMeta = product.metaData.find((meta: { key: string; value: any }) =>
    meta.key &&
    meta.key.toLowerCase().includes('video') &&
    meta.key !== "woodmart_wc_video_gallery" &&
    meta.value
  );

  if (videoMeta) {
    const url = videoMeta.value;
    // 判断视频类型
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return { url, type: 'youtube' };
    } else if (url.includes('vimeo.com')) {
      return { url, type: 'vimeo' };
    } else {
      return { url, type: 'mp4' };
    }
  }

  return { url: '', type: 'mp4' };
}

/**
 * 从元数据中获取标题
 */
function getTitleFromMetaData(product: any) {
  if (!product?.metaData) return product?.name || '';

  const titleMeta = product.metaData.find((meta: { key: string; value: any }) =>
    meta.key === "index-video-title" && meta.value
  );

  return titleMeta ? titleMeta.value.replace(/<[^>]*>/g, '') : product?.name || '';
}

/**
 * 处理YouTube视频URL，提取ID
 */
function processYoutubeUrl(url: string) {
  if (!url) return '';

  // 处理URL中的换行符和多余空格
  const cleanUrl = url.trim().replace(/[\n\r]/g, '');

  // 标准YouTube链接格式 (youtube.com/watch?v=ID)
  const standardRegExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
  const standardMatch = cleanUrl.match(standardRegExp);

  if (standardMatch && standardMatch[1]) {
    return standardMatch[1];
  }

  // 备用匹配方法 - 直接提取参数
  if (cleanUrl.includes('youtube.com/watch')) {
    const urlObj = new URL(cleanUrl);
    const videoId = urlObj.searchParams.get('v');
    if (videoId && videoId.length === 11) {
      return videoId;
    }
  }

  // 短链接格式 (youtu.be/ID)
  const shortRegExp = /youtu\.be\/([^"&?\/\s]{11})/i;
  const shortMatch = cleanUrl.match(shortRegExp);

  if (shortMatch && shortMatch[1]) {
    return shortMatch[1];
  }

  // 最后尝试通用匹配
  const generalRegExp = /([a-zA-Z0-9_-]{11})/;
  const generalMatch = cleanUrl.match(generalRegExp);

  return (generalMatch && generalMatch[1].length === 11) ? generalMatch[1] : '';
}

/**
 * 处理Vimeo视频URL，提取ID
 */
function processVimeoUrl(url: string) {
  if (!url) return '';

  // 匹配Vimeo视频ID的正则表达式
  const regExp = /vimeo\.com\/([0-9]+)/;
  const match = url.match(regExp);

  return match ? match[1] : '';
}

function Aaa({
  title,
  show,
  type,
  index,
  href,
}: {
  title: string;
  show: boolean;
  type: "next" | "pre";
  index: number;
  href: string;
}) {
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (show) {
      divRef.current!.style.display = "";
      type === "next" ? (divRef.current!.className = "next-enter") : (divRef.current!.className = "pre-enter");
    } else {
      type === "next" ? (divRef.current!.className = "next-leave") : (divRef.current!.className = "pre-leave");

      function animationend() {
        divRef.current!.style.display = "none";
        divRef.current?.removeEventListener("animationend", animationend);
      }
      divRef.current?.addEventListener("animationend", animationend);
    }
  }, [show]);

  return (
    <div ref={divRef}>
      <div
        className={cn("relative overflow-hidden pb-1 text-center text-[28px] font-bold text-white md:text-[44px]")}
      >
        {title}
      </div>
      <div className={cn("z-[3] flex w-full items-center justify-center gap-4 text-white max-md:text-sm")}>
        <Link href={href || '/'} className="flex items-center" prefetch={true}>
          <span className="hover:underline">Buy Now</span>
          <i className="ri-arrow-right-s-line pt-0.5 text-lg"></i>
        </Link>
      </div>
    </div>
  );
}

export default function HomeBanner2() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [playbackErrors, setPlaybackErrors] = useState({});
  const videoRefs = useRef<{ [key: number]: HTMLVideoElement }>({});
  const isMobile = useMobile();
  const swiperRef = useRef<SwiperCore>();
  const swiper1Ref = useRef<SwiperCore>();
  const [type, setType] = useState<"next" | "pre">("next");

  // 轮播图配置
  const swiperConfig = {
    speed: 1000,
    autoplayDelay: 7000,
  };

  // 加载视频产品数据
  useEffect(() => {
    async function fetchVideoProducts() {
      try {
        setLoading(true);
        const { data } = await client.query({
          query: INDEX_PRODUCT_VIDEO
        });
        console.log("data---", data);

        if ((data as any)?.indexProductVideo?.edges?.length) {
          const videoItems = (data as any).indexProductVideo.edges.map((edge: any) => {
            const product = edge.node;
            const video = getVideoFromMetaData(product);
            console.log(video);

            const title = getTitleFromMetaData(product);

            const posterImage = product?.galleryImages?.nodes?.[0]?.sourceUrl ||
              product?.image?.sourceUrl ||
              '/image/home/banner2/1.webp';

            return {
              title,
              img: posterImage,
              video: video.url,
              videoType: video.type,
              href: `/product/${product.slug}`,
              youtubeId: video.type === 'youtube' ? processYoutubeUrl(video.url) : '',
              vimeoId: video.type === 'vimeo' ? processVimeoUrl(video.url) : ''
            };
          }).filter((item: any) => item.video);

          setItems(videoItems);
        }
      } catch (error) {
        console.error('Error fetching video products:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchVideoProducts();
  }, []);



  // 通过 JavaScript 控制 iframe 视频播放
  useEffect(() => {
    // 等待 iframe API 准备就绪
    function tryToControlYoutubeVideos() {
      if (typeof window !== 'undefined' && window.document) {
        const iframes = document.querySelectorAll('iframe[src*="youtube.com"]');
        iframes.forEach((iframe, idx) => {
          // 使用 postMessage 控制播放状态而不是重新渲染
          try {
            const youtubeIndex = items.findIndex((item: any) =>
              // @ts-ignore
              item.videoType === 'youtube' && iframe.src.includes(item.youtubeId)
            );

            if (youtubeIndex !== -1) {
              const command = currentIndex === youtubeIndex ? 'playVideo' : 'pauseVideo';
              // @ts-ignore
              iframe.contentWindow?.postMessage(JSON.stringify({
                event: 'command',
                func: command,
                args: []
              }), '*');
            }
          } catch (e) {
            console.warn('YouTube iframe control error:', e);
          }
        });
      }
    }

    // 给 iframe 留出加载时间
    const timer = setTimeout(tryToControlYoutubeVideos, 1000);
    return () => clearTimeout(timer);
  }, [currentIndex, items]);


  useEffect(() => {
    setTimeout(() => {
      const videos = document.querySelectorAll<HTMLVideoElement>(".home-banner2-video");
      // console.log(videos);

      videos.forEach((el: any) => {
        if (el) {
          el.currentTime = 0;
          el.pause();
        }
      });

      videos[activeIndex]?.play();
    }, 1000);
  }, [activeIndex]);
  // 骨架屏加载组件
  const BannerSkeleton = () => (
    <div className="home-banner2 relative h-[480px] w-full xl:h-[600px] 2xl:h-[720px]">
      <Skeleton className="absolute inset-0 w-full h-full bg-primary/10" />
      {/* <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-xl px-4">
        <Skeleton className="h-10 w-3/4 mx-auto mb-6" />
        <Skeleton className="h-8 w-1/2 mx-auto mb-4" />
        <Skeleton className="h-12 w-32 mx-auto" />
      </div> */}
    </div>
  );

  // 空状态组件
  const EmptyState = () => (
    <div className="home-banner2 relative h-[480px] w-full bg-gray-900 flex flex-col items-center justify-center xl:h-[600px] 2xl:h-[720px]">
      <div className="text-white text-2xl mb-4">No Data</div>
      <Link href="/" className="text-white bg-[#3eb036] hover:bg-[#3eb036]/90 px-6 py-2 rounded-md">
        Go Home
      </Link>
    </div>
  );

  // 加载中显示骨架屏
  if (loading) {
    return <BannerSkeleton />;
  }

  // 没有数据显示空状态
  if (!loading && items.length === 0) {
    // return <EmptyState />;
    return <></>;
  }
  return (
    <div className="home-banner2 relative">
      <Swiper
        className="h-[480px] w-full xl:h-[600px] 2xl:h-[720px]"
        spaceBetween={isMobile ? 0 : 16}
        slidesPerView={isMobile ? 1 : 1.65}
        navigation={{
          nextEl: ".home-swiper2-button-next",
          prevEl: ".home-swiper2-button-prev",
        }}
        loop={true}
        centeredSlides
        pagination={{
          clickable: true,
          bulletActiveClass: "home-swiper-pagination-bullet-active",
          bulletClass: "home-swiper-pagination-bullet",
        }}
        onSwiper={(swiper) => {
          swiper1Ref.current = swiper;
        }}
        autoplay={{
          delay: swiperConfig.autoplayDelay,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        onSlideNextTransitionStart={() => {
          setType("next");
        }}
        onSlidePrevTransitionStart={() => {
          setType("pre");
        }}
        onSlideChange={(swiper) => {
          if (swiperRef.current) {
            try {
              swiperRef.current.slideTo(swiper.activeIndex, swiperConfig.speed);
            } catch (err) {
              console.warn('slide navigation error:', err);
            }
          }
          setActiveIndex(swiper.activeIndex);
          setCurrentIndex(swiper.realIndex);
        }}
        speed={swiperConfig.speed}
      >
        {items.map((item, index) => (
          <SwiperSlide key={`video-${index}`}>
            <div className="relative h-full overflow-hidden text-white">
              {(item as any).videoType === 'youtube' && (item as any).youtubeId ? (
                <div className="absolute inset-0 h-full w-full bg-black">
                  <iframe
                    key={`youtube-${(item as any).youtubeId}`}
                    className="w-full h-full"
                    src={`https://www.youtube.com/embed/${(item as any).youtubeId}?autoplay=${currentIndex === index ? 1 : 0}&mute=1&loop=1&playlist=${(item as any).youtubeId}&controls=0&showinfo=0&rel=0&modestbranding=1&playsinline=1&enablejsapi=1&version=3&origin=${typeof window !== 'undefined' ? window.location.origin : ''}`}
                    title={(item as any).title}
                    loading={currentIndex === index ? "eager" : "lazy"}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (item as any).videoType === 'vimeo' && (item as any).vimeoId ? (
                <div className="absolute inset-0 h-full w-full bg-black">
                  <iframe
                    key={`vimeo-${(item as any).vimeoId}`}
                    className="w-full h-full"
                    src={`https://player.vimeo.com/video/${(item as any).vimeoId}?autoplay=${currentIndex === index ? 1 : 0}&muted=1&loop=1&background=1&quality=1080p&transparent=0`}
                    title={(item as any).title}
                    loading={currentIndex === index ? "eager" : "lazy"}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (item as any).videoType === 'mp4' && (item as any).video ? (
                <video
                  // @ts-ignore
                  ref={el => el && (videoRefs.current[index] = el)}
                  muted
                  loop
                  playsInline
                  autoPlay={currentIndex === index ? true : false}
                  poster={(item as any).img}
                  preload="metadata"
                  className="home-banner2-video absolute inset-0 h-full w-full object-cover"
                >
                  <source src={(item as any).video} type="video/mp4" />
                </video>
              ) : (
                <div
                  className="absolute inset-0 h-full w-full bg-center bg-cover bg-no-repeat"
                  style={{ backgroundImage: `url(${(item as any).img || '/image/home/banner2/1.webp'})` }}
                />
              )}
            </div>
          </SwiperSlide>
        ))}

        <div className="absolute left-1/2 top-1/2 z-[2] h-12 w-[50vw] -translate-x-1/2 -translate-y-1/2 max-md:hidden">
          <div className="home-swiper2-button-prev absolute left-12 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full duration-300 hover:bg-[rgba(0,0,0,0.3)]">
            <i className="ri-arrow-left-s-line text-4xl text-white"></i>
          </div>
          <div className="home-swiper2-button-next absolute right-12 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full duration-300 hover:bg-[rgba(0,0,0,0.3)]">
            <i className="ri-arrow-right-s-line text-4xl text-white"></i>
          </div>
        </div>
      </Swiper>

      <div className="absolute left-1/2 top-12 z-[2] h-[130px] -translate-x-1/2 overflow-hidden">
        <Swiper
          className="absolute top-1/2 h-[130px] max-w-full -translate-y-1/2"
          slidesPerView={isMobile ? 1 : 1.65}
          navigation={{
            nextEl: ".home-swiper2-button-next",
            prevEl: ".home-swiper2-button-prev",
          }}
          centeredSlides
          loop={true}
          direction="vertical"
          effect={"fade"}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => {
            try {
              setCurrentIndex(swiper.realIndex);
            } catch (err) {
              console.warn('Error updating slide index:', err);
            }
          }}
          speed={swiperConfig.speed}
        >
          {items.map((item:any, index) => (
            <SwiperSlide key={`title-${index}`} className="relative flex flex-col items-center justify-center">
              <Aaa title={item.title} show={currentIndex === index} index={index} href={item.href || ""} type={type} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
