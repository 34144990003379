import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AddToCartButton } from "@/components/ShoppingCart/addToCart";
import { useProductAttributeStore } from "@/store/product-attribute.store";
import parse from "html-react-parser";
import { useState, useEffect } from "react";
import { cn } from "@/lib/utils";
import Link from "next/link";
import client from "@/lib/ApolloClient/apolloClient";
import { GqlProductBySlugInterface } from "@/lib/types/gql/product/product-by-slug.type";
import PRODUCT_BY_SLUG from "@/lib/queries/product-by-slug";
import { Rate } from 'antd';
import { useCalculatorStore } from "@/store/calculator.store";
import Calculator from "@/components/Calculator";
import { SocialShare } from "@/components/SocialShare";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import ImageGallery from "@/components/ProductSeries/imageGallery";
import SwiperCore, { Navigation } from "swiper";
import Information from "@/components/ProductSeries/information";
import CurrentProductPrice from "@/components/ProductSeries/current-proudct-price";
import ProductCartBuyInquiry from "@/components/ProductSeries/product-cart-buy-inquiry";
SwiperCore.use([Navigation]);
interface ProductDetailModalProps {
  isOpen: boolean;
  slug: string | null;
  onClose: () => void;
}

const ProductDetailModal = ({ isOpen, slug, onClose }: ProductDetailModalProps) => {

  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<any>(null);



  useEffect(() => {
    const fetchProductData = async () => {
      if (!slug || !isOpen) return;

      setIsLoading(true);
      try {
        const res = await client.query<GqlProductBySlugInterface>({
          query: PRODUCT_BY_SLUG,
          variables: { slug },
        });
        setProduct(res.data.product);
      } catch (error) {
        console.error("Error fetching product data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductData();
  }, [slug, isOpen]);


  return (
    <Modal
      title={null}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      centered
      className="product-modal"
      width="90%"
      style={{ maxWidth: '1200px',zIndex:49 }}
    >
      {isLoading ? (
        <div className="flex justify-center items-center py-20">
          <LoadingOutlined style={{ fontSize: 36, color: "#3eb036" }} />
        </div>
      ) : (
        <div className="">
          {product ? (
            <>
              <div className=" md:flex md:justify-between max-md:grid max-md:grid-cols-1 gap-x-12 bg-white pt-5  max-md:py-8">
                <ImageGallery product={product} />
                <Information product={product} />
              </div>
              <div className="py-5 md:mt-6">
                <div className="container flex md:justify-end items-center gap-x-4 gap-y-4 flex-wrap">
                  <div>
                    <CurrentProductPrice product={product} />
                  </div>
                  <div className="max-md:flex-1">
                    <ProductCartBuyInquiry {...product} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center py-10">
              Loading Error
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ProductDetailModal; 