"use client";
import Head from "next/head";
import React, { useEffect, useState } from "react";
// import { useRouter } from "next/router";
import { usePathname } from "next/navigation";
import { cApiUrl } from "@/lib/utils";
import { ContactEmail, ContactPhone } from "@/lib/constants/contact";

export default function HeadSeo({ seo }: { seo?: { [key: string]: any } }) {
  const pathname = usePathname(); // 获取当前路径
  if (!seo) {
    return null;
  }

  const keywords = () => {
    if (seo?.keywords?.related_keywords?.length) {
      return seo.keywords.related_keywords.join(",");
    }
    return "";
  };
  const url = cApiUrl + pathname;
  const image = "/favicon.ico";

  return (
    <>
      {/* Meta Keywords */}
      {keywords() && <meta name="keywords" content={keywords()} />}

      {/* Canonical Link */}
      <link rel="canonical" href={url} />
      <meta property="og:url" content={url} />

      {/* Open Graph Meta Tags */}
      <meta property="og:type" content="website" />
      {seo.title && (
        <>
          <meta property="og:title" content={seo.title} />
          <title>{seo.title}</title>
        </>
      )}
      {seo.description && (
        <>
          <meta name="description" content={seo.description} />
          <meta property="og:description" content={seo.description} />
          <meta name="twitter:description" content={seo.description} />
        </>
      )}
      <meta property="og:image" content={image} />

      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />
      <meta
        name="googlebot"
        content="index, follow, noimageindex, max-video-preview:-1, max-image-preview:large, max-snippet:-1"
      />
      {/* Additional SEO Metadata */}
      <meta name="referrer" content="origin-when-cross-origin" />
      <meta name="format-detection" content="telephone=no, address=no, email=no" />

      {/* Apple Web App Meta Tags */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="apple-mobile-web-app-title" content={seo.title || "App"} />

      {/* Open Graph Extended */}
      {/*<meta property="og:locale" content={localLanguage} />*/} {/* 动态设置语言 */}
      <meta property="og:site_name" content={process.env.NEXT_PUBLIC_COMPANY_NAME} />

      {/* Twitter Extended */}
      <meta name="twitter:site" content="@site" />
      <meta name="twitter:creator" content="@creator" />

      {/* Author and Publisher Meta Tags */}
      <meta name="author" content={process.env.NEXT_PUBLIC_COMPANY_NAME} />
      <meta name="publisher" content={process.env.NEXT_PUBLIC_COMPANY_NAME} />
      <meta name="generator" content="Next.js" />
    </>
  );
}
