"use client";

import useMobile from "@/lib/hooks/useMobile";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { getProductData } from "@/server/action";
import { useEffect, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import client from "@/lib/ApolloClient/apolloClient";
import { PRODUCT_INDEX_CATEGORY } from "@/lib/queries/product-new-featured-bestSelling";
import { GqlProductNewFeatBestInterface } from "@/lib/types/product/product-new-feat-best.type";
import Image from "next/image";
import { RightOutlined } from '@ant-design/icons';

// 元数据类型定义
type IMetaData = {
  id: string;
  key: string;
  value: string;
  _typename?: string;
};

/**
 * 从元数据中获取指定键的值
 * @param metaData 元数据数组
 * @param key 要查找的键名
 * @returns 找到的值或空字符串
 */
function getMetaDataValue(metaData: IMetaData[] | undefined, key: string): string {
  if (!metaData || !Array.isArray(metaData)) return '';
  
  const item = metaData.find(meta => meta?.key === key);
  return item?.value || '';
}

const category = [
  {
    name: "Illumination Products",
    des: "Portable lighting products",
    img: "/image/home/category/5.webp",
    mImg: "/image/home/category/5-m.webp",
    // href: "/illumanation",
    // 随后台sku更换路径
    href: "/product/t5-set-760-lumen-400-meter-long-range-hunting-flashlight-set"
  },
  {
    name: "Lighting Accessories",
    des: "Portable lighting products",
    img: "/image/home/category/2.webp",
    mImg: "/image/home/category/2-m.webp",
    href: "/product/hm2-lightweight-multi-angle-flashlight-mount",
  },
  {
    name: "Multi-tool Products",
    des: "Portable lighting products",
    img: "/image/home/category/6.webp",
    mImg: "/image/home/category/6-m.webp",
    href: "/product/nextorch-multi-tool-pocket-knife-pliers-folding-mini-portable-fold-outdoor-hunting-survival-rescue-multipurpose-repair-tool",
  },
  {
    name: "NEX Batons",
    des: "Portable lighting products",
    img: "/image/home/category/4.webp",
    mImg: "/image/home/category/4-m.webp",
    href: "/product/15-nex-walker-flashlight-baton",
  },
];
export default function ProductList() {
  const isMobile = useMobile();
  const [productList, setProdcutList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const getProductListData = async () => {
    try {
      setLoading(true)
      const res = await client.query<GqlProductNewFeatBestInterface>({
        query: PRODUCT_INDEX_CATEGORY,
      })
      console.log("indexcateres------", res);
      const { indexProductsCategories } = res.data
      setProdcutList(indexProductsCategories.edges)
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false)
    }
  }
  // const getProductCategoryData = async () => {
  //   try {
  //     setLoading(true)

  //   } catch (err: any) {
  //     console.log(err);
  //   } finally {
  //     setLoading(false)
  //   }
  // }
  useEffect(() => {
    getProductListData()
  }, [])

  return (
    <div className="md:p-4 md:pb-0 max-md:pt-2">
      <ul className="grid md:grid-cols-2 grid-cols-1 max-md:gap-2 md:gap-4">
        {
          loading ? new Array(4).fill(0).map((_, index) => { return <Skeleton className="md:h-[696px] h-[528px] " key={index} /> })
            :
            productList?.length > 0 ?
              productList.map((item: any) => {

                // 新增分类匹配逻辑
                const targetSlugs = [
                  'tactical-flashlights',
                  'patrol-flashlights',
                  'weapon-lights',
                  'nex-baton'
                ];

                const matchedCategory = item.node?.productCategories?.edges?.find(
                  (edge: any) => targetSlugs.includes(edge.node.slug)
                )?.node || item.node?.productCategories?.edges?.[0]?.node;

                // 获取元数据值
                const subTitle = getMetaDataValue(item.node.metaData, 'index-sub-title');
                const subDesc = getMetaDataValue(item.node.metaData, 'index-sub-desc');

                return (
                  <li
                    key={item.node.databaseId}
                    className="relative xl:h-[696px] h-[528px]"
                    style={{
                      background: `url(${item.node?.galleryImages?.nodes[0]?.sourceUrl  ??  item.node?.image?.sourceUrl}) no-repeat`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundColor: "#f5f5f5"
                    }}
                  >
                    <Link href={`/${matchedCategory?.slug}`} className="inline-block w-full h-full">
                      <div className="mt-12  mx-auto text-center flex flex-col px-5 pb-[3rem]">
                        <h5 className="text-[22px] text-[#00000073] line-clamp-1" dangerouslySetInnerHTML={{ __html: subTitle }}></h5>
                        <h4 className="font-bold text-[#000000d9] text-[32px] line-clamp-1 my-4">{matchedCategory?.name}</h4>
                        <p className="text-[#000000d9] text-[24px] line-clamp-1 lg:mb-10 mb-5" dangerouslySetInnerHTML={{ __html: subDesc }}></p>
                        <div className="flex items-center gap-5 justify-center">
                          {/* <Button size="lg" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                            READ MORE
                          </Button> */}
                          <Link href={`/${matchedCategory?.slug}`} className="text-[#000000d9] text-[18px]  group">
                            <span className="group-hover:underline">Learn More</span>
                            <span className="text-[#000000d9] ] text-[16px] ml-1"><RightOutlined /></span>
                          </Link>
                          <Link href={`/product/${item.node.slug}`} className="text-[#000000d9] text-[18px]  group">
                            <span className="group-hover:underline">Buy Now</span>
                            <span className="text-[#000000d9] ] text-[16px] ml-1"><RightOutlined /></span>
                          </Link>
                        </div>
                        {/* <img src={item.node?.image?.sourceUrl} alt={item.node.name} className="md:h-[500px] h-[300px] mx-5 my-5 object-contain" /> */}
                        {/* <div className="flex items-center gap-5 justify-center lg:hidden">
                          <Button size="default" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                            READ MORE
                          </Button>
                          <Button size="default" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                            BUY NOW
                          </Button>
                        </div> */}
                      </div>
                    </Link>
                  </li>
                )
              })
              : ""
        }

        {/* {category.map((item) => (
          <li
            key={item.name}
            className="md:h-[696px] h-[528px] bg-no-repeat bg-center bg-cover relative"
            style={{ backgroundImage: `url(${isMobile ? item.mImg : item.img})` }}
          >
            <Link href={item.href} className=" absolute inset-0">
              <div className="mt-12  mx-auto text-center flex flex-col">
                <h4 className="font-bold text-[#101010]  lg:text-4xl text-2xl ">{item.name}</h4>
                <p className=" text-[#333333] lg:text-2xl text-lg mb-4 max-md:mb-2 mt-1">{item.des}</p>
                <div className="flex items-center gap-5 justify-center max-lg:hidden">
                  <Button size="lg" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                    READ MORE
                  </Button>
                </div>
                <div className="flex items-center gap-5 justify-center lg:hidden">
                  <Button size="default" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                    READ MORE
                  </Button>
                  <Button size="default" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                    BUY NOW
                  </Button>
                </div>
              </div>
            </Link>
          </li>
        ))} */}
      </ul>
    </div>
  );
}
