"use client";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { memo, useEffect, useState, useRef } from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import useMobile from "@/lib/hooks/useMobile";
import { Button } from "../ui/button";
import Link from "next/link";
import client from "@/lib/ApolloClient/apolloClient";
import { GqlProductBySlugInterface, GqlProductBySlugNodeInterface } from "@/lib/types/gql/product/product-by-slug.type";
import PRODUCT_BY_SLUG from "@/lib/queries/product-by-slug";
import { AddToCartButton } from "@/components/ShoppingCart/addToCart";
import { useProductAttributeStore } from "@/store/product-attribute.store";
import axios from "axios";
import ProductDetailModal from "@/components/product/ProductDetailModal";
SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);
const spaceBetween = 0;
const slidesPerView = 1;
// https://pinshop147.pinshop.com/wp-json/ps-product-ga/v1/admin/banner/get
const banners = [
  {
    title1: "P86",
    title2: "Sound And Light Integrated <br />Electronic Whistle Flashlight",
    title3: `
      <li>Sound And Light In One</li>
      <li>|</li>
      <li>120 Decibels</li>
      <li>|</li>
      <li>1600 Lumens</li>
      <li>|</li>
      <li>Broken Window Attack Head</li>
    `,
    img: "/image/home/banner/banner1.webp",
    mImg: "/image/home/banner/banner1-m.webp",
    slug: "p86-1600lm-flashlight-with-120db-electronic-whistle",
  },
  {
    title1: "V35 X-Carry",
    title2: "NQD Platform Modular <br />Flashlight Quick Pull Set",
    title3: `
      <li>Light weight And Simple</li>
      <li>|</li>
      <li>Side Pull Lock</li>
      <li>|</li>
      <li>Bottom Clear</li>
      <li>|</li>
      <li>Can Be Rotated 360°</li>
    `,
    img: "/image/home/banner/banner2.webp",
    mImg: "/image/home/banner/banner2-m.webp",
    slug: "v35-quick-draw-holder",
  },
];
type BannerInterface = {
  btnText: string;
  image: string;
  key: string;
  link: string;
  position: string;
  text: string;
  title: string;
  weight: number;
}
export default memo(function HomeBanner() {
  const [currentIndex, setCurrentIndex] = useState(0); // 用于存储当前index的状态
  const [hasFetchedInitial, setHasFetchedInitial] = useState<boolean>(false);
  const [bannerData, setBannerData] = useState<BannerInterface[]>([])
  // useEffect(() => {
  //   console.log(currentIndex);
  // }, [currentIndex]);
  // 创建一个 state 用来存储 Swiper 实例
  const [swiperInstance, setSwiperInstance] = useState<any | null>(null);
  const { selectAttribute } = useProductAttributeStore()
  const isMobile = useMobile();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSlug, setCurrentSlug] = useState<string | null>(null);
  const [loopEnabled, setLoopEnabled] = useState(false);
  const getBannerData = async () => {
    try {
      const res = await axios(`${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/ps-product-qa/v1/front/banner/get`);
      console.log(res.data.data);
      // const data = await res.json();
      console.log("data---", res.data);
      if (res.data.code === 1) {
        console.log("data---", res.data.data);
        setBannerData(res.data.data)
      }
      // console.log(data);
    } catch (error) {
      console.error('Error fetching banner data:', error);
      return [];
    }
  }
  useEffect(() => {
    getBannerData()
  }, [])
  
  // 获取当前产品slug并打开模态框
  const handleBuyNowClick = (item: BannerInterface) => {
    // 从link中提取slug
    if (item.link) {
      const slug = item.link.split('/').pop() || '';
      
      if (slug) {
        setCurrentSlug(slug);
        setIsModalOpen(true);
      }
    }
  };
  
  // 关闭模态框
  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentSlug(null); // 关闭时重置slug
  };
  
  return (
    <>
      <Swiper
        className="group relative h-[576px] max-h-screen w-full md:h-[800px]"
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        speed={1000}
        onSwiper={(swiper) => {
          setSwiperInstance(swiper);
          // 初始化时设置轮播状态
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        effect={"fade"}
        navigation={{
          // hideOnClick: true,
          nextEl: ".home-swiper-button-next",
          prevEl: ".home-swiper-button-prev",
        }}
        loop={loopEnabled}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        // pagination={{
        //   clickable: true,
        //   bulletActiveClass: "home-swiper-pagination-bullet-active",
        //   bulletClass: "home-swiper-pagination-bullet",
        // }}
        onSlideChange={(swiper) => {
          setCurrentIndex(swiper.realIndex);
          // 更新轮播位置状态
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
      >
        {bannerData.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="h-full text-white">
              <div className="relative h-full w-full">
                {/* <Image
                  priority
                  alt="banner"
                  src={item.image}
                  quality={100}
                  fill
                  style={{ objectFit: "cover" }}
                /> */}
                <img src={item.image} alt="banner" className="h-full w-full object-cover" />
                <div
                  className={cn(
                    `container absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-md:text-center md:px-32`,
                  )}
                >
                  <div className={cn("mb-2 space-y-2 md:space-y-5 max-md:items-center max-md:flex max-md:flex-col")}>
                    <h2
                      className={cn(
                        "text-2xl font-bold text-main transition-all md:text-5xl xl:text-[50px]",
                        currentIndex == index
                          ? "translate-y-0 opacity-100 duration-1000"
                          : "translate-y-[-100px] opacity-0 duration-0",
                      )}
                    >
                      {item.title}
                    </h2>
                    <h4
                      className={cn(
                        "text-xl font-bold text-white transition-all md:text-4xl md:leading-[50px] xl:text-5xl xl:leading-[60px] max-md:text-center",
                        currentIndex === index
                          ? "translate-y-0 opacity-100 duration-1000"
                          : "translate-y-[-100px] opacity-0 duration-0",
                      )}

                    // dangerouslySetInnerHTML={{ __html: item.title2 }}
                    >
                      {/* {parse(item.text)} */}
                      {item.text}
                    </h4>
                    <div
                      className={cn(
                        "space-x-5 pt-[8px] transition-all max-md:hidden flex",
                        currentIndex === index
                          ? "translate-y-0 opacity-100 delay-700 duration-300"
                          : "translate-y-[-25px] opacity-0 duration-0",
                      )}
                    >
                      <Link href={item.link} className="w-[147px] h-[37.33px] rounded-[5px] inline-block">
                        <Button size="lg" className="w-full h-full">{item.btnText}</Button>
                      </Link>
                      
                      <Button 
                        size="lg" 
                        variant="ghost" 
                        className="w-[147px] h-[37.33px] rounded-[5px] border-[1px] border-white"
                        onClick={() => handleBuyNowClick(item)}
                      >
                        BUY NOW
                      </Button>
                    </div>
                    
                    {/* 移动端版本也需要添加点击事件 */}
                    <div
                      className={cn(
                        "transition-all md:hidden flex-col flex items-center",
                        currentIndex === index
                          ? "translate-y-0 opacity-100 delay-700 duration-300"
                          : "translate-y-[-25px] opacity-0 duration-0",
                      )}
                    >
                      <Link href={item.link} className=" h-[32px] w-[95px] inline-block">
                        <Button size="sm" className="w-full h-full">{item.btnText}</Button>
                      </Link>
                      
                      <div className="mt-2">
                        <Button 
                          size="sm" 
                          variant="ghost" 
                          className="h-[32px] w-[95px] border-[1px] border-white"
                          onClick={() => handleBuyNowClick(item)}
                        >
                          BUY NOW
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="absolute left-0 top-1/2 z-[2] hidden h-24 w-full -translate-y-1/2 group-hover:block">
          <div className={cn(
            "home-swiper-button-prev absolute left-0 flex h-full w-12 cursor-pointer items-center justify-center rounded-lg duration-300",
            isBeginning ? "opacity-50 pointer-events-none" : "hover:bg-[rgba(0,0,0,0.3)]"
          )}>
            <i className="ri-arrow-left-s-line text-4xl text-white"></i>
          </div>
          <div className={cn(
            "home-swiper-button-next absolute right-0 flex h-full w-12 cursor-pointer items-center justify-center rounded-lg duration-300",
            isEnd ? "opacity-50 pointer-events-none" : "hover:bg-[rgba(0,0,0,0.3)]"
          )}>
            <i className="ri-arrow-right-s-line text-4xl text-white"></i>
          </div>
        </div>
      </Swiper>
      
      {/* 使用修改后的ProductDetailModal组件 */}
      <ProductDetailModal 
        isOpen={isModalOpen}
        slug={currentSlug} 
        onClose={handleModalClose} 
      />
    </>
  );
});
