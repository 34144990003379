import { gql } from "@apollo/client";

const productQuery = ` edges{
      node{
        databaseId
        name
        shortDescription
        description
        averageRating
        slug
        ... on SimpleProduct{
          price
          regularPrice
          stockStatus
          productCategories(last: 100) {
              edges {
                node {
                  name
                  slug
                }
              }
          }
          galleryImages {
            nodes {
              sourceUrl
            }
          }
        }
        metaData(keysIn:["index-sub-title","index-sub-desc","store-video-title","store-video-desc","index-video-title","woodmart_wc_video_gallery","min_quantity"]){
            value
            id
            key
        }
        image {
            sourceUrl
        }
        sku
        ... on VariableProduct{
          price
          regularPrice
          stockStatus
          productCategories(last: 100) {
              edges {
                node {
                  name
                  slug
                }
              }
          }
          galleryImages {
            nodes {
              sourceUrl
            }
          }
        }
      }
    }`;

export const PRODUCT_NEW_FEAT_BEST = gql`
{
  newProducts:products(first: 10, where: {tagIn:"new",orderby: {field: DATE, order: DESC} }) {
    ${productQuery}
  },
  featuredProducts: products(first: 10, where: {featured: true}) {
       ${productQuery}
  },
   bestSellingProducts: products(first: 10, where: {orderby: {field: TOTAL_SALES, order: DESC}}) {
   ${productQuery}
  }
}
`;

export const PRODUCT_INDEX_CATEGORY = gql`
{
  indexProductsCategories:products(first: 4, where: {tagIn:"index",orderby: {field: DATE, order: DESC} }) {
    ${productQuery}
  },
}
`;


export const STOTE_PRODUCT_VIDEO = gql`
{
  storeProductVideo:products(first: 5, where: {tagIn:"store-video",orderby: {field: DATE, order: DESC} }) {
    ${productQuery}
  },
}
`;

export const INDEX_PRODUCT_VIDEO = gql`
{
  indexProductVideo:products(first: 10, where: {tagIn:"index-video",orderby: {field: DATE, order: DESC} }) {
    ${productQuery}
  },
}
`;